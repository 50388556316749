import { setResponsiveImage } from "../../../utilities/set-image";

export const createCategoryListItem = (item, index) => {
  const categoryUrl = item.querySelector("a")
    ? item.querySelector("a").getAttribute("href")
    : "#";
  const categoryName = item.querySelector(".carousel__labelText").innerHTML;
  const isEditorial =
    item.parentElement.parentElement.parentElement.classList.contains(
      "productCat-editorial"
    );
  let itemCtaStyle = item.querySelector(".carousel__labelWrap").style.cssText;

  const imageUrl = item.querySelector("img").getAttribute("data-src");

  return `
  <li class="carousel__item" id="category_${index}">
    <a
      href="${categoryUrl}"
      target="_self"
      rel="nofollow"
    >
      <div class="carousel__image__group">
      ${setResponsiveImage({
        imageUrl,
      })}
      </div>
      <div class="carousel__label" style="${itemCtaStyle}">
        <label class="product__name">${categoryName}</label>
      </div>
    </a>
  </li>
  `;
};
