import { createCarousel } from "../carousel/utils/createCarousel";
import { callApi } from "./utils/callApi";
import { generateViewAll } from "./utils/generateViewAll";
import { createArticleListItem } from "./utils/createArticleListItem";
import { type } from "jquery";

export const ArticleFeed = async (element) => {
  const articles = await callApi(
    element.getAttribute("data-feed-url"),
    element
  );

  const viewAllButton = element.querySelector(".viewAllArticles a");
  const viewAll = {
    href: viewAllButton.getAttribute("href"),
    style: viewAllButton.getAttribute("style"),
    hoverColor: viewAllButton.dataset.hoverColor,
    hoverBorder: viewAllButton.dataset.hoverBorder,
    hoverBackground: viewAllButton.dataset.hoverBackground,
    text: viewAllButton.querySelector("span").innerHTML,
  };
  const options = {
    type: "carousel",
    prev: element.querySelectorAll(".carousel__controls")[0].dataset,
    next: element.querySelectorAll(".carousel__controls")[1].dataset,
    extraListItem: generateViewAll(viewAll),
    maxPageSize: 4,
    listItemFunc: createArticleListItem(true, false),
    mobilePageSize: 1,
    previewNextPagePercentage: 0,
  };

  element.innerHTML = createCarousel(articles, options, element);
  element.classList.add("no-spin");
};
