export const createCta = (options, products) => {
  const hostname = JSON.parse(
    document.querySelector(
      'script[type="application/json"][data-id="window.asos.siteChrome._initialStore"]'
    ).textContent
  ).regionalStore.storeUrls.default;

  return `<div class="cta-wrapper">
  <a 
    class="cta js-buttonHover ${products.length === 0 ? "disabled" : ""}
    "
    ${
      products.length === 0
        ? `aria-disabled="true"`
        : `href="${hostname}discover/as-seen-on-me/?category_id=${
            options.categoryId
          }&auth_token=${options.authToken}#ctaref=${
            options.ctaRef || "ugc+view+gallery"
          }"`
    }
    style="
      border-color: ${options.ctaBorderColour};
      background: ${options.ctaFillColour};
      color: ${options.ctaColour};
    "
    data-hover-color="${options.ctaHoverColour}"
    data-hover-border="${options.ctaHoverBorderColour}"
    data-hover-background="${options.ctaHoverFillColour}"
    data-analyticsId="${options.analyticsId}"
  >
    ${options.ctaText.toUpperCase()}
  </a>
</div>`;
};
