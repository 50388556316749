import configureLogger from "asos-web-logging";
import { configureRequest } from "@asosteam/asos-web-request";
import pkg from "../../../../package.json";

const makeApiCallURL = ({ categoryId, sortingOption, authToken, count, filter, rightsGiven, taggedGalleries }) => {
  return `https://photorankapi-a.akamaihd.net/categories/${categoryId}/media/${sortingOption}?auth_token=${authToken}&count=${count}&filter_media_without_products=${filter}&rights_given=${rightsGiven}&include_tagged_galleries=${taggedGalleries}`;
};

export const callApi = async (options) => {
  const url =
    options.testApiUrl ||
    makeApiCallURL({
      categoryId: options.categoryId,
      sortingOption: "recent",
      authToken: options.authToken,
      count: 12,
      filter: 1,
      rightsGiven: 1,
      taggedGalleries: 0,
    });

  const logger = configureLogger({
    moduleName: "get-products-by-category",
    maxLogLevel: "info",
  });

  const request = configureRequest({
    retries: 2,
    defaultTimeout: 10000,
    retryDelay: () => 100,
    logger,
    client: {
      name: "asos-web-ui-library",
      version: pkg.version,
      platform: "web",
    },
  });

  try {
    const result = await request.get({
      url,
    });

    if (result.data?.data?._embedded?.media)
      return result.data.data._embedded.media;
    return [];
  } catch (error) {
    console.log(error);

    return [];
  }
};
