import { createCarousel } from "../carousel/utils/createCarousel";
import { createCta } from "./html-elements/cta";
import { callApi } from "./utils/callApi";
import { getOptions } from "./utils/getOptions";

const groupItems = (items, maxPageSize = 4) => {
  const groupedItems = [];
  let group = [];
  items.forEach((item, index) => {
    group.push(item);
    if (group.length === maxPageSize) {
      groupedItems.push(group);
      group = [];
    }
    if (index === items.length - 1 && group.length > 0) {
      groupedItems.push(group);
    }
  });
  return groupedItems;
};

const findProduct = (item) => {
  const streams = item._embedded["streams:all"]._embedded.stream;
  const streamWithNumericTag = streams.find(
    (stream) => !isNaN(parseInt(stream.tag_based_key))
  );
  return streamWithNumericTag || {};
};

const handleApiData = (data, layout) => {
  const items = data.map((item, index) => {
    const product = findProduct(item);
    return {
      productUrl: product.product_url,
      ariaLabel: product.description,
      imageUrl: item.images.normal,
      itemIndex: index,
    };
  });

  const itemsPerPage = layout === "mobile" ? 4 : 1;
  const groupedItems = groupItems(items, itemsPerPage);

  return groupedItems;
};

const appendCtaRef = (url, ctaref, featureref1) => {
  const separator = url.includes("?") ? "&" : "?";
  return `${url}${separator}ctaref=${ctaref}&featureref1=${featureref1}`;
};

const createCategoryListItem = (products, ctaRef) => (itemsArray, index) => {
  const nextPage = products[index + 1];
  return `
    <li class="carousel__item">
    ${itemsArray
      .map(
        (item) => `
        <a href="${appendCtaRef(
          item.productUrl,
          `${ctaRef || ""}+ugc+asom+carousel_${item.itemIndex}`,
          "ugc+asom+carousel"
        )}" aria-label="${item.ariaLabel}">
          <img src="${item.imageUrl}" alt="${item.ariaLabel}" />
        </a>`
      )
      .join("")}
      ${!nextPage ? `<div class="last-page-spacer"></div>` : ""}
    </li>
  `;
};

export const UGCGallery = async (element, layout) => {
  const options = getOptions(element);
  const data = await callApi(options);

  if (options.editorial) {
    element.parentNode.classList.add("ugc-editorial");
  }

  const headline = `<h2 style="color: ${options.headlineColour}">${options.headline}</h2>`;
  const description = `<h3 style="color: ${options.descriptionColour}">${options.description}</h3>`;

  const products = handleApiData(data, layout);

  const carouselConfig = {
    rootAriaLabel: "Carousel",
    type: "carousel",
    root: "carousel",
    headline: `<header>${headline}${description}</header>`,
    next: {
      buttonColor: options.buttonColour,
    },
    prev: {
      buttonColor: options.buttonColour,
    },
    cta: options.ctaText ? createCta(options, products) : "",
    maxPageSize: 4,
    mobilePageSize: 1,
    hideDots: false,
    listItemFunc: createCategoryListItem(products, options.ctaRef),
  };
  element.innerHTML = createCarousel(products, carouselConfig);
  element.classList.add("no-spin");
};
