export const setImage = (element) => {
  const { imageMobile, imageDesktop, altMobile, altDesktop } = element.dataset;

  const picture = document.createElement("picture");
  picture.classList.add("hero__image");

  if (imageMobile) {
    const mobileSource = document.createElement("source");
    mobileSource.srcset = imageMobile;
    mobileSource.media = "(max-width: 736px)";
    picture.appendChild(mobileSource);
  }

  const desktopSource = document.createElement("img");
  desktopSource.srcset = imageDesktop;
  desktopSource.alt = altDesktop || "";
  picture.appendChild(desktopSource);

  element.appendChild(picture);
};

const isInline = (imageUrl) => imageUrl.startsWith("data:image");
const isRelative = (imageUrl) => imageUrl.startsWith("/");
const hasProtocol = (imageUrl) => imageUrl.startsWith("https://");
const isFromScene7 = (url) => url.includes("images.asos-media.com");

export const setResponsiveImage = ({ imageUrl }) => {
  let imageSrc;

  if (isInline(imageUrl) || isRelative(imageUrl)) {
    imageSrc = imageUrl;
  } else {
    const url = `${!hasProtocol(imageUrl) ? "//" : ""}${imageUrl}`;

    imageSrc = isFromScene7(url)
      ? `${url.split("?")[0]}?$n_640w$&wid=513&fit=constrain`
      : url;
  }

  return `<img
src="data:image/svg+xml;charset=utf8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' style%3D'stroke-width: 0px; background-color: %23eee;' viewBox%3D'0 0 132 169'%2F%3E"
data-src="${imageSrc}"
alt=""
class="carousel__image"
/>`;
};
